<!-- Start Page Title Area -->
<div class="page-title-area bg-requestaquote">
    <div class="container">
        <div class="page-title-content">
            <h2>Request a Quote</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Request a Quote</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="requestaquote-area requestaquote-area-three pt-100 pb-70">
    <div class="container">
        <div class="requestaquote-contact-area">
            <div class="section-title-full">
                <span>Get it for FREE!</span>
                <h2>Request a Quote</h2>	
				<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
            </div>

            <form id="contactForm">
                <div class="row">
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Company Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Company Name">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="regno" id="regno" class="form-control" placeholder="Business Registration Number">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="taxno" id="taxno" class="form-control" placeholder="Business Tax Number">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Address</h6>	
					</div>
					
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Address Line 1">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Address Line 2">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="City">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="State">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Contact Details</h6>	
					</div>
					
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Mobile Number">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Fax Number">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Email Address">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Website Address">
                        </div>
                    </div>
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Registration Details</h6>	
					</div>
					
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Currency">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Company Turnover">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Territory Limit Form">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Territory Limit To">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Upload the declaration CSV</h6>	
					<p>CSV only. Maximum upload file size 3mb</p>
					</div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
                    

                    <div class="col-lg-12 col-md-12 pt-70">
                        <button type="submit" class="default-btn btn-two">Get A Quote</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>