<!-- Start Welcome Area -->
<section class="welcome-area">
    <div class="container">
        <div class="section-title">
            <span>Welcome to Revor</span>
            <h2>Since 25 Years, Our Company Has Been Working Very Faithfully</h2>
        </div>

        <div class="welcome-bg ptb-100">
            <div class="welcome-content">
                <h2>Our Goal Is to Provide Quality Service to Customers</h2>
                <p class="some-bold">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                <a routerLink="/about-us" class="default-btn">Read More</a>
            </div>
        </div>
    </div>
</section>
<!-- End Welcome Area -->