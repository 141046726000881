<!-- Start Page Title Area -->
<div class="page-title-area bg-profile">
    <div class="container">
        <div class="page-title-content">
            <h2>Claims</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Claims</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="profile-area profile-area-three pt-100 pb-70">
   
   <div class="container">
	<div class="row">
					<div class="col-lg-3 col-md-3">					
					<div class="left-widget">
                    <h3>My Account</h3>
                    <ul class="import-link-left">
                        <li><a routerLink="/profile">My Profile</a></li>
                        <li><a routerLink="/declaration">Declaration</a></li>
                        <li><a routerLink="/profileclaims">Claims</a></li>
						<li><a routerLink="/mycomponents">My Components</a></li>
                        <li><a routerLink="/">Logout</a></li>
                    </ul>
					</div>				
					
					</div>

					<div class="col-lg-9 col-md-9">
					
					<div class="container" class="pb-40">
					<div class="row">
					<div class="col-lg-12 col-md-12">
						<form class="search-box">
							<input type="text" name="Search" placeholder="Search Consignment Note" class="form-control">
						</form>
					</div>					
					</div>
					</div>		
					
					<table class="table table-striped">
					  <thead>
						<tr>
						  <th scope="col">Draft ID</th>
						  <th scope="col">Product Description</th>
						  <th scope="col">Created Date</th>
						  <th scope="col">Amount (RM)</th>
						  <th scope="col">Action</th>
						</tr>
					  </thead>
					  <tbody>
						<tr>
						  <td>DWT123456</td>
						  <td>MacBook Pro 2020</td>
						  <td>13-04-2020</td>
						  <td>128.00</td>
						  <td><a routerLink="/" style="font-weight:700;">View</a></td>
						</tr>
						<tr>
						 <td>DWT123456</td>
						  <td>MacBook Pro 2021</td>
						  <td>13-04-2020</td>
						  <td>128.00</td>
						  <td><a routerLink="/" style="font-weight:700;">View</a></td>
						</tr>
						<tr>
						  <td>DWT123456</td>
						  <td>MacBook Pro 2020</td>
						  <td>13-04-2020</td>
						  <td>128.00</td>
						  <td><a routerLink="/" style="font-weight:700;">View</a></td>
						</tr>
					  </tbody>
					</table>
					</div>							
	</div>
	</div>
   
   
</section>