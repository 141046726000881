<!-- Start Page Title Area -->
<div class="page-title-area bg-7">
    <div class="container">
        <div class="page-title-content">
            <h2>Claims</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Claims</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Claims Area -->
<section class="faq-area faq-area-three pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="faq-content">
                    <span class="top-title">Reporting a Claim</span>
                    <h3>What You need to Make a Claim</h3>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
					<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>

                <ul>
                    <li>It has survived not only five centuries, but also the leap into electronic typesetting.</li>
                    <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                    <li>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</li>
                    <li>Various versions have evolved over the years, sometimes by accident sometimes on purpose.</li>
                </ul>
                    
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="faq-img-three">
                   <section class="main-contact-area pb-100">
    <div class="container">
        <div class="faq-contact-area">
            <div class="section-title">
                <span>Start Your Claim</span>
                <h3>Claim Your Consignment</h3>
            </div>

            <form id="contactForm">
                <div class="row">
                    <div class="col-lg-12 col-sm-12">
                        <div class="form-group">
                            <input type="text" name="consignmentnote" id="consignmentnote" class="form-control" placeholder="Enter Consignment Note">
                        </div>
                    </div>                   

                    <div class="col-lg-12 col-md-12">
                        <a routerLink="/claimsform" class="default-btn btn-two">Submit</a>
						<!--<button type="submit" class="default-btn btn-two">Submit</button>-->
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Claims Area -->