<!-- Start Page Title Area -->
<div class="page-title-area bg-21">
    <div class="container">
        <div class="page-title-content">
            <h2>Login</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Login</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start User Area -->
<section class="user-area-style ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form-action mb-50">
                    <div class="account-title">
                        <h2>Log In to Your Account</h2>
                    </div>

                    <form method="post">
                        <div class="form-group">
                            <label>Email or Phone</label>
                            <input class="form-control" type="text" name="name">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input class="form-control" type="password" name="password">
                        </div>

                        <div class="login-action">
                            <span class="log-rem">
                                <input id="remember-2" type="checkbox" style="margin-right:5px;">
                                <label>Remember me!</label>
                            </span>
                            <span class="forgot-login">
                                <a routerLink="/recover-password">Forgot your password?</a>
                            </span>
                        </div>
						<div class="row align-items-center">
                            <div class="col-lg-4 col-sm-6">
                                <button class="default-btn" type="submit">Log In Now</button>
                            </div>
                            <div class="col-lg-8 col-sm-6">
                                <div class="right">                                    
                                    <label>Don’t have an account? <a routerLink="/register">Free to Sign Up</a></label>
                                </div>
                            </div>
                        </div>
                        
                    </form>
                </div>
            </div>

            
        </div>
    </div>
</section>
<!-- End User Area -->