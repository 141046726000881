<!-- Start Page Title Area -->
<div class="page-title-area bg-23">
    <div class="container">
        <div class="page-title-content">
            <h2>Terms & Conditions</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Terms & Conditions</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Terms & Conditions Area -->
<section class="terms-conditions-area ptb-100">
    <div class="container">
        <div class="terms-conditions">
            <div class="title">
                <span>Terms & Conditions</span>
                <h2>Smart Parcel Terms of Service</h2>
            </div>

            <div class="conditions-content">
                <h3>1. Health Insurance?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>

                <ul>
                    <li>It has survived not only five centuries, but also the leap into electronic typesetting.</li>
                    <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                    <li>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</li>
                    <li>Various versions have evolved over the years, sometimes by accident sometimes on purpose.</li>
                </ul>
            </div>

            <div class="conditions-content">
                <h3>2. What is Health Insurance?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
            </div>

            <div class="conditions-content">
                <h3>3. Modifications to the Service</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
            </div>

            <div class="conditions-content">
                <h3>4. Types of Health Insurance</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>

                <ul>
                    <li>It has survived not only five centuries, but also the leap into electronic typesetting.</li>
                    <li>Contrary to popular belief, Lorem Ipsum is not simply random text.</li>
                    <li>The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters.</li>
                    <li>Various versions have evolved over the years, sometimes by accident sometimes on purpose.</li>
                </ul>
            </div>

            <div class="conditions-content">
                <h3>5. Do I Need Health Insurance?</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip</p>
            </div>

            <div class="conditions-content five mb-0">
                <h3>6. Types of Health Insurance</h3>
                <p class="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut abore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea com modo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore fugiat.</p>
            </div>
        </div>
    </div>
</section>
<!-- End Terms & Conditions Area -->