<!-- Start Header Area -->
<header class="header-area">

    <!-- Start Top Header -->
    <div class="top-header">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-7">
                    <ul class="header-left-content">
                        <li>
                            <i class="bx bx-envelope"></i>
                            <a href="mailto:hello@smartparcelsolutions.com">hello@smartparcelsolutions.com</a>
                        </li>
                        <li>
                            <i class="bx bx-phone"></i>
                            +1 (514) 312-5678
                        </li>
                    </ul>
                </div>

                <div class="col-lg-4 col-md-5">
                    <div class="header-right-content">
                        <!-- <ul class="language-area">
                            <li class="language-item-top">
                                <a href="#" class="language-bar">
                                    <span>Language</span>		
                                    <i class="bx bx-chevron-down"></i>
                                </a>

                                <ul class="language-item-bottom">
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/english.png" alt="Image">
                                            English
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/arab.png" alt="Image">
                                            العربيّة
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/germany.png" alt="Image">
                                            Deutsch
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/portugal.png" alt="Image">
                                            󠁥󠁮󠁧󠁿Português
                                        </a>
                                    </li>
                                    <li class="language-item">
                                        <a href="#" class="language-link">
                                            <img src="assets/img/language/china.png" alt="Image">
                                            简体中文
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </ul>-->

                        <div class="log-in">
                            <a href="#" data-toggle="modal" data-target="#staticBackdrop">
                                Log In
                            </a>
                        </div>

                        <div class="register">
                            <a href="#" data-toggle="modal" data-target="#staticBackdrop-2">
                                Register
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Start Top Header -->
    
    <!-- Start Navbar Area -->
    <div class="navbar-area">
        <div class="mobile-nav">
            <div class="container-fluid">
                <div class="mobile-menu">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="desktop-nav">
            <div class="container-fluid">
                <nav class="navbar navbar-expand-md navbar-light">
                    <a class="navbar-brand" routerLink="/"><img src="assets/img/logo.png" alt="logo"></a>

                    <div class="collapse navbar-collapse mean-menu">
                        <ul class="navbar-nav m-auto">
							<li class="nav-item"><a routerLink="/" class="nav-link">Home</a></li>	
							<li class="nav-item"><a routerLink="/beapartner" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Be a Partner</a></li>
							<li class="nav-item"><a routerLink="/requestaquote" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Request a Quote</a></li>
							<li class="nav-item"><a routerLink="/claims" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Claims</a></li>
							<li class="nav-item"><a routerLink="/howto" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">How-to</a></li>
							<li class="nav-item"><a routerLink="/faq" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">FAQ</a></li>
							<li class="nav-item"><a routerLink="/contact-us" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}" class="nav-link">Contact Us</a></li>

							<li class="nav-item">
                                <a href="javascript:void(0)" class="nav-link">My Account <i class="bx bx-chevron-down"></i></a>
    
                                <ul class="dropdown-menu">								
									<li class="nav-item"><a routerLink="/login" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Login</a></li>
                                    <li class="nav-item"><a routerLink="/register" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Register</a></li>
									<li class="nav-item"><a routerLink="/verifyotp" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Verify OTP</a></li>
                                    <li class="nav-item"><a routerLink="/regsuccess" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Success</a></li>
                                    <li class="nav-item"><a routerLink="/recover-password" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recover Password</a></li>
                                    <li class="nav-item"><a routerLink="/profile" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">User Profile</a></li>
                                    <li class="nav-item"><a routerLink="/declaration" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Declaration</a></li>
								    <li class="nav-item"><a routerLink="/profileclaims" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Profile Claims</a></li>
									<li class="nav-item"><a routerLink="/mycomponents" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">My Components</a></li>
                                </ul>
                            </li>
							
							
                        </ul>
                        
                       <!-- <div class="others-option">
                            <form class="search-box">
                                <input type="text" name="Search" placeholder="Search for..." class="form-control">
                                <button type="submit" class="search-btn"><i class="bx bx-search"></i></button>
                                <button type="submit" class="close-btn"><i class="bx bx-x"></i></button>
                            </form>
                            
                            <div class="call-us">
                                <i class="bx bx-phone-call"></i>
                                <a href="tel:+1-(514)-312-5678">+1 (514) 312-5678</a>
                            </div>
                            
                            <div class="get-quote">
                                <a routerLink="/pricing" class="default-btn">Get a Quote</a>
                            </div>
                        </div>-->
                    </div>
                </nav>
            </div>
        </div>

       <!--  <div class="others-option-for-responsive">
            <div class="container">
                <div class="dot-menu">
                    <div class="inner">
                        <div class="circle circle-one"></div>
                        <div class="circle circle-two"></div>
                        <div class="circle circle-three"></div>
                    </div>
                </div>
                
                <div class="container">
                    <div class="option-inner">
                        <div class="others-option justify-content-center d-flex align-items-center">
                            <div class="call-us">
                                <i class="bx bx-phone-call"></i>
                                <a href="tel:+1-(514)-312-5678">+1 (514) 312-5678</a>
                            </div>
                            
                            <div class="get-quote">
                                <a routerLink="/pricing" class="default-btn">Get a Quote</a>
                            </div>

                            <form class="search-box">
                                <input type="text" name="Search" placeholder="Search for..." class="form-control">
                                <button type="submit" class="search-btn"><i class="bx bx-search"></i></button>
                                <button type="submit" class="close-btn"><i class="bx bx-x"></i></button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
    <!-- End Navbar Area -->

</header>
<!-- End Header Area -->