<!-- Start Page Title Area -->
<div class="page-title-area bg-20">
    <div class="container">
        <div class="page-title-content">
            <h2>OTP Verification</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">OTP Verification</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Recover Password Area -->
<section class="user-area-style recover-password-area ptb-100">
    <div class="container">
        <div class="contact-form-action recover">
            <div class="form-heading text-center">
                <h3 class="form-title">Verify OTP</h3>
                <p class="reset-desc">Enter the OTP sent to +6010 1234567</p>
            </div>
            
             <form method="post">
                        <div class="form-group">
                            <label>Enter OTP</label>
                            <input class="form-control" type="text" name="name" placeholder="Enter OTP Code">
                        </div>
						
						<div class="row align-items-center">
                            <div class="col-lg-6 col-sm-6">
                                <button class="default-btn" type="submit">Verify &amp; Proceed</button>
                            </div>
                            <div class="col-lg-6 col-sm-6">
                                <div class="right">                                    
                                    <button class="default-btn" type="submit">Resend OTP</button>
                                </div>
                            </div>
                        </div>
                        
                    </form>
        </div>
    </div>
</section>
<!-- End Recover Password Area -->