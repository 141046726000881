<!-- Start Services Area -->
<section class="services-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Featured Services</span>
            <h2>How it Works!</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="bx bx-pen"></i>
                    <h3>Declare</h3>
                    <p>Declare your parcel.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="bx bx-anchor"></i>
                    <h3>Approve</h3>
                    <p>Assessment of your parcel from our Partner.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="bx bx-happy"></i>
                    <h3>Pay &amp; Cover</h3>
                    <p>Make payment and your parcel's covered.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

        <!--    <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-property-insurance"></i>
                    <h3>Property Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-travel-insurance"></i>
                    <h3>Travel Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-box">
                    <i class="flaticon-car-insurance"></i>
                    <h3>Auto Insurance</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                    <a routerLink="/business-insurance" class="read-more">Read More</a>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <a routerLink="/services" class="default-btn">View More</a>
            </div>-->
        </div>
    </div>
</section>
<!-- End Services Area -->