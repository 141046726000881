<app-hometwo-banner></app-hometwo-banner>

<app-hometwo-future></app-hometwo-future>

<app-hometwo-welcome></app-hometwo-welcome>

<app-funfacts></app-funfacts>

<app-hometwo-services></app-hometwo-services>

<app-hometwo-why-choose-us></app-hometwo-why-choose-us>

<app-hometwo-get-a-quote></app-hometwo-get-a-quote>

<app-team></app-team>

<app-partner></app-partner>

<app-hometwo-feedback></app-hometwo-feedback>

<app-blog></app-blog>