<!-- Start Blog Area -->
<section class="blog-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Blog</span>
            <h2>Our Latest News & Blogs</h2>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-1.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li>
                                <i class="flaticon-user"></i>
                                <a routerLink="/blog-grid">Jackson</a>
                            </li>
                            <li>
                                <i class="flaticon-calendar"></i>
                                October 27, 2020
                            </li>
                        </ul>
                        <h3><a routerLink="/blog-details">Why Life Insurance Is a Force For Social Good</a></h3>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-2.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li>
                                <i class="flaticon-user"></i>
                                <a routerLink="/blog-grid">Peterson</a>
                            </li>
                            <li>
                                <i class="flaticon-calendar"></i>
                                October 28, 2020
                            </li>
                        </ul>
                        <h3><a routerLink="/blog-details">5 Things to Know About Insurance Policy</a></h3>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog-post">
                    <div class="blog-img">
                        <a routerLink="/blog-details">
                            <img src="assets/img/blog/blog-3.jpg" alt="Image">
                        </a>
                    </div>
                    <div class="blog-content">
                        <ul>
                            <li>
                                <i class="flaticon-user"></i>
                                <a routerLink="/blog-grid">Wiliyams</a>
                            </li>
                            <li>
                                <i class="flaticon-calendar"></i>
                                October 29, 2020
                            </li>
                        </ul>
                        <h3><a routerLink="/blog-details">Global Trends in The Home Insurance Industry</a></h3>
                        <a routerLink="/blog-details" class="read-more">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Area -->