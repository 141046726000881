<!-- Start Page Title Area -->
<div class="page-title-area bg-7">
    <div class="container">
        <div class="page-title-content">
            <h2>Claims Form</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Claims Form</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="beapartner-area beapartner-area-three pt-100 pb-70">
    <div class="container">
        <div class="beapartner-contact-area">
            <div class="section-title-full">
                <span>Fill the Form</span>
                <h2>Continue with the Claim</h2>	
				<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p> 
            </div>
				

            <form id="contactForm" class="get-quote-form">
                <div class="row">
					
                    <div class="col-lg-6 col-sm-6 pt-20">
					<h6>Details</h6>
						Consignment Note: 12345<br />
						Policy Number: INV1234<br />
						Delivered by: GD Express<br />
						Send from: Kepong<br />
						Deliver to: Bangsar<br />
						Submitted by: Calvin
                    </div>	
					
					<div class="col-lg-6 col-sm-6 pt-20">
					<h6>Items</h6>
						Product Description:  MacBook Pro 2020
						Quantity: x1<br />
						Pick Up Date: 18-04-2021<br />
						Tracking No.: 11224444<br />
						Invoice No.: 8473685<br />
						Invoice Date: 02-04-2021<br />
						Unit Price (RM): 6399.00<br />
						Total Surcharge (RM): 100.00
						
						<p class="pt-20"><strong>Total Payable (RM): 200.00</strong></p>
					
					<strong>Remarks</strong>
					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> 
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Claim Details</h6>	
					</div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Date of Loss">
                        </div>
                    </div>	
					
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Estimated Loss Amount (RM)">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Upload Documents</h6>	
					</div>

                   					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Copy of Airway Bill / Delivery Note / Consignment Note(whichever available)</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Photographs showing the damaged packaging and item.</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Purchase invoice for damaged/missing parcel.</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Repair bill/invoice for damaged item.</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Tracking report from the courier service provider</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">confirmation from courier service provider on the missing parcel</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Require police report (applicable to theft loss)</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">E-Payment Form duly completed and signed by Customer</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" > 
							<a routerLink="/">Download e-payment form</a>
                        </div>					
                    </div>		

                    <div class="col-lg-4 col-md-4 pt-40">
                        <button type="submit" class="reject-btn btn-two">Back</button>
                    </div>
					<div class="col-lg-8 col-md-8 pt-40">
                        <button type="submit" class="default-btn btn-two">Submit Claim</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>