<!-- Start Page Title Area -->
<div class="page-title-area bg-success">
    <div class="container">
        <div class="page-title-content">
            <h2>Success</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Success</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="success-area success-area-three pt-100 pb-70">
    <div class="container">
        <div class="success-contact-area">
            <div class="section-title-full">
                <span>Thank You!</span>
                <h2>Registration Successful</h2>	
				<p>Thank you for registering with us. You are now ready for declaration process. Thank you for verifying your mobile number. <br />Create <strong>New Declaration</strong> to cover your shipment note.</p>
            </div>
           
        </div>
    </div>
</section>