<!-- Start Page Title Area -->
<div class="page-title-area bg-21">
    <div class="container">
        <div class="page-title-content">
            <h2>Register</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Register</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->


<!-- Start User Type -->
<section class="user-area-style pt-100 pb-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form-action">
                    <div class="account-title">
                        <h2>Register Your Account</h2>
                    </div>

                    <form class="get-quote-form">
                        <div class="form-group">
						<label>Customer Type</label>
							<select>
								<option value="0">Select Customer Type</option>
								<option value="1">You are an Individual</option>
								<option value="2">You are a Business</option>
							</select>	
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-sm-12">
                                <button class="default-btn register" type="submit">Next</button>
                            </div>                            
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End User Area -->

<!-- Start User Area -->
<section class="user-area-style pt-40 pb-20">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form-action">
                    <div class="account-title">
                        <h2>Register Your Account</h2>
                    </div>

                    <form class="get-quote-form">			
						<div class="form-group">
						<label>Customer Type</label>
							<select>
								<option value="0">Select Customer Type</option>
								<option value="1" selected>You are an Individual</option>
								<option value="2">You are a Business</option>
							</select>	
                        </div>
                        <div class="form-group">
                            <label>Full name</label>
                            <input class="form-control" type="text" name="name"  placeholder="Enter Name">
                        </div>

                        <div class="form-group">
                            <label>IC Number</label>
                            <input class="form-control" type="text" name="email" placeholder="Enter IC No.">
                        </div>

                        <div class="form-group">
                            <label>Mobile no. (A 6-digit TAC will be SMS to this number)</label>
                            <input class="form-control" type="text" name="Number" placeholder="Enter Mobile Number">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input class="form-control" type="text" name="email" placeholder="Enter Email Address">
                        </div>

						<div class="row align-items-center">
                            <div class="col-lg-12 col-sm-12">
                                <button class="default-btn register" type="submit">Register Now</button>
                            </div>
							<div class="col-lg-12 col-sm-12" style="text-align:center;">
								<label><a routerLink="/">Already have an account? Login here</a></label>
                            </div>
						</div>	
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End User Area -->

<!-- Start Business Area -->
<section class="user-area-style pt-40 pb-40">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="contact-form-action">
                    <div class="account-title">
                        <h2>Register Your Account</h2>
                    </div>

                    <form class="get-quote-form">			
						<div class="form-group">
						<label>Customer Type</label>
							<select>
								<option value="0">Select Customer Type</option>
								<option value="1">You are an Individual</option>
								<option value="2" selected>You are a Business</option>
							</select>	
                        </div>
                        <div class="form-group">
                            <label>Company Name</label>
                            <input class="form-control" type="text" name="name"  placeholder="Enter Company Name">
                        </div>

                        <div class="form-group">
                            <label>Business Registration No</label>
                            <input class="form-control" type="regno." name="email" placeholder="Enter Business Registration No">
                        </div>

                        <div class="form-group">
                            <label>Company Tax No.</label>
                            <input class="form-control" type="text" name="Number" placeholder="Enter Company Tax No.">
                        </div>

                        <div class="form-group">
                            <label>Company Address 1</label>
                            <input class="form-control" type="text" name="email" placeholder="Enter Company Address 1">
                        </div>
						
						<div class="form-group">
                            <label>Company Address 2</label>
                            <input class="form-control" type="text" name="email" placeholder="Enter Company Address 2">
                        </div>
						
						<div class="form-group">
                            <label>City</label>                            
							<select>
								<option value="1">Select City From</option>
								<option value="2">City 1</option>
								<option value="3">City 2</option>
								<option value="4">City 3</option>
								<option value="5">City 4</option>
							</select>	                        
                        </div>
						
						<div class="form-group">
                            <label>State</label>                            
							<select>
								<option value="1">Select State From</option>
								<option value="2">State 1</option>
								<option value="3">State 2</option>
								<option value="4">State 3</option>
								<option value="5">State 4</option>
							</select>	                        
                        </div>
						
						<div class="form-group">
                            <label>Mobile no. (A 6-digit TAC will be SMS to this number)</label>
                            <input class="form-control" type="text" name="Number" placeholder="Enter Mobile Number">
                        </div>
						
						<div class="form-group">
                            <label>Fax No.</label>
                            <input class="form-control" type="text" name="Number" placeholder="Enter Fax Number">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input class="form-control" type="text" name="email" placeholder="Enter Email Address">
                        </div>
						
						<div class="form-group">
                            <label>Website</label>
                            <input class="form-control" type="text" name="email" placeholder="Enter Website Address">
                        </div>
						
						<div class="form-group">
                            <label>Create Password</label>
                            <input class="form-control" type="text" name="email" placeholder="Enter Password">
                        </div>

                        <div class="row align-items-center">
                            <div class="col-lg-12 col-sm-12">
                                <button class="default-btn register" type="submit">Register Now</button>
                            </div>
							<div class="col-lg-12 col-sm-12" style="text-align:center;">
								<label><a routerLink="/">Already have an account? Login here</a></label>
                            </div>
						</div>	
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End User Area -->