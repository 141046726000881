<!-- Start Page Title Area -->
<div class="page-title-area bg-mycomponents">
    <div class="container">
        <div class="page-title-content">
            <h2>My Components</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">My Components</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Area -->
<section class="mycomponents-area mycomponents-area-three pt-100 pb-70">
   
   <!-- Start Create Declaration Area -->
   <div class="container">
		<div class="row">
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>Create Declaration</h2>				
				</div>
				
				<form id="contactForm" class="get-quote-form">
                <div class="row">				
					
                    <div class="col-lg-4 col-sm-4">
						<label>Consignment Note./Reference No.</label>
                        <div class="form-group">							
                            <input type="text" name="name" id="name" class="form-control" placeholder="Enter Consignment Note">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
						<label>Delivered by</label>
                        <div class="form-group">
                                    <select>
                                        <option value="1">Select Courier Service</option>
                                        <option value="2">Pedins</option>
                                        <option value="3">Tenisu</option>
                                        <option value="4">Corinu</option>
                                        <option value="5">Insuxo</option>
                                    </select>	
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
						<label>Promo code</label>
                        <div class="form-group">	
                            <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Promo Code">
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 pt-40">
                        <button type="submit" class="reject-btn btn-two">Cancel</button>
                    </div>
					<div class="col-lg-8 col-md-8 pt-40">
                        <button type="submit" class="default-btn btn-two">Next</button>
                    </div>
                </div>
            </form>
				
											
			</div>
		</div>
	</div>
	<!-- End Create Declaration Area -->
	
	<!-- Start Location Area -->
   <div class="container pt-70">
		<div class="row">
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>Location</h2>				
				</div>
				
				<form id="contactForm" class="get-quote-form">
                <div class="row">				
					
                    <div class="col-lg-6 col-sm-6">
						<label>From Country</label>
                        <div class="form-group">							
                           <select>
								<option value="1">Select Country From</option>
								<option value="2">Malaysia</option>
								<option value="3">China</option>
								<option value="4">India</option>
								<option value="5">Australia</option>
							</select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>From City</label>
                        <div class="form-group">
							<select>
								<option value="1">Select City From</option>
								<option value="2">City 1</option>
								<option value="3">City 2</option>
								<option value="4">City 3</option>
								<option value="5">City 4</option>
							</select>	
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>To Country</label>
                        <div class="form-group">							
                           <select>
								<option value="1">Select Country To</option>
								<option value="2">Malaysia</option>
								<option value="3">China</option>
								<option value="4">India</option>
								<option value="5">Australia</option>
							</select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>To City</label>
                        <div class="form-group">
							<select>
								<option value="1">Select City To</option>
								<option value="2">City 1</option>
								<option value="3">City 2</option>
								<option value="4">City 3</option>
								<option value="5">City 4</option>
							</select>	
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-4 pt-40">
                        <button type="submit" class="reject-btn btn-two">Cancel</button>
                    </div>
					<div class="col-lg-8 col-md-8 pt-40">
                        <button type="submit" class="default-btn btn-two">Next</button>
                    </div>
                </div>
            </form>
				
											
			</div>
		</div>
	</div>
	<!-- End Create Location Area -->
	
	<!-- Start Add Product Area -->
   <div class="container pt-70">
		<div class="row">
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>Add Product</h2>				
				</div>
				
				<form id="contactForm" class="get-quote-form">
                <div class="row">				
					
                    <div class="col-lg-6 col-sm-6">
						<label>Product Description</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Product Description">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Quantity</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Quantity">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Pickup Date</label>
                        <div class="form-group">							
                           <input type="date" name="promocode" id="promocode" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Tracking No.</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Tracking No.">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Invoice No.</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Invoice No.">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Invoice Date</label>
                        <div class="form-group">							
                           <input type="date" name="promocode" id="promocode" class="form-control">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Coverage</label>
                        <div class="form-group">							
                           <select>
								<option value="1">Select Coverage</option>
								<option value="2">Test 1</option>
								<option value="3">Test 2</option>
								<option value="4">Test 3</option>
								<option value="5">Test 4</option>
							</select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Rate</label>
                        <div class="form-group">
							<input type="text" name="promocode" id="promocode" class="form-control" placeholder="1.6%" disabled>	
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Unit Price (RM)</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Unit Price">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Total Value (RM)</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Total Value">
                        </div>
                    </div>					
					
					<div class="col-lg-12 col-md-12 pt-40">
                        <button type="submit" class="default-btn btn-two">Add Product</button>
                    </div>					

					<div class="col-lg-12 col-md-12 pt-20">	
						
					<table class="table">
					  <thead>
						<tr>
						  <th scope="col">No.</th>
						  <th scope="col">Prod. Desc.</th>
						  <th scope="col">Quantity</th>
						  <th scope="col">Pickup Date</th>
						  <th scope="col">Tracking No.</th>
						  <th scope="col">Invoice No.</th>
						  <th scope="col">Invoice Date</th>
						  <th scope="col">Unit Price</th>
						  <th scope="col">Total Value</th>
						  <th scope="col">Action</th>
						</tr>
					  </thead>
					  <tbody>
						<tr>
						  <td>1</td>
						  <td>MacBook Pro 2020</td>
						  <td>01</td>
						  <td>13-04-2020</td>
						  <td>1259663</td>
						  <td>VFG5896</td>						  
						  <td>13-04-2020</td>
						  <td>6392.00</td>
						  <td>200.00</td>
						  <td><i class="bx bx-pen"></i> | <i class="bx bx-trash"></i></td>
						</tr>
						<tr>
						  <td colspan="8"></td>
						  <td>Total Declared Value (MYR)</td>
						  <td>6399.00</td>
						</tr>
						<tr>
						  <td colspan="8"></td>
						  <td>Minimal Surcharge (MYR)</td>
						  <td>100.00</td>
						</tr>
						<tr>
						  <td colspan="8"></td>
						  <td>Total Payable (MYR)</td>
						  <td>200.00</td>
						</tr>
					  </tbody>
					</table>
						
					</div>
					
                    <div class="col-lg-4 col-md-4 pt-40">
                        <button type="submit" class="reject-btn btn-two">Cancel</button>
                    </div>
					<div class="col-lg-8 col-md-8 pt-40">
                        <button type="submit" class="default-btn btn-two">Next</button>
                    </div>
                </div>
            </form>
				
											
			</div>
		</div>
	</div>
	<!-- End Add Product Area -->
	
	
	
	<!-- Start Confirmation Area -->
   <div class="container pt-70">
		<div class="row">	
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>Confirmation</h2>			
				</div>
				
				<div class="row borderbox">	
				<div class="col-lg-12 col-md-12">	
				<h5>Details</h5>
				</div>
				<div class="col-lg-4 col-md-4" style="color:#000;">	
				Consignment Note <br />
				Delivered by<br />
				Send from<br />
				Deliver to<br />
				Submitted by
				</div>
				<div class="col-lg-8 col-md-8">	
				12345<br />
				GD Express<br />
				Kepong<br />
				Bangsar<br />
				Calvin
				</div>				
				<div class="col-lg-12 col-md-12 pt-20">	
				<h5>Items</h5>
				</div>
				<div class="col-lg-4 col-md-4" style="color:#000;">	
				Total Product<br />
				Total Unit<br />
				Total XC Surcharge (MYR)<br />
				Total Payable (MYR)<br />
				</div>
				<div class="col-lg-8 col-md-8">	
				x1<br />
				x1<br />
				100.00<br />
				200.00
				</div>	
				
				<div class="col-lg-12 col-md-12 pt-20">	
				<h5>Remarks</h5>
				</div>
				<div class="col-lg-12 col-md-12">					
				<input type="text" name="remark" id="remark" class="form-control" placeholder="Enter remarks">							
				</div>
				<div class="col-lg-12 col-md-12">
				<span class="log-rem">
					<input id="remember-2" type="checkbox" style="margin-right:5px;">
					<label>I have agreed to the <a routerLink="/terms-conditions">terms and conditions.</a></label>
				</span>
				</div>
				<div class="col-lg-4 col-md-4 pt-20">
				<button type="submit" class="default-btn btn-two">Save as draft</button>
				</div>	
				<div class="col-lg-8 col-md-8 pt-20">
				<button type="submit" class="default-btn btn-two">Make Payment</button>
				</div>	
	
			</div>
			</div>
			
			
			
		</div>
	</div>
	<!-- End Confirmation Area -->
	
	
	<!-- Start Submit Claim Area -->
	<div class="container pt-70">
		<div class="row">	
		
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>Submit Claim</h2>			
				</div>
				
				<form id="contactForm">
                <div class="row">
				
					
					<div class="col-lg-6 col-sm-6 pt-20">
					<h6>Details</h6>
						Consignment Note: 12345<br />
						Policy Number: INV1234<br />
						Delivered by: GD Express<br />
						Send from: Kepong<br />
						Deliver to: Bangsar<br />
						Submitted by: Calvin
                    </div>	
					
					<div class="col-lg-6 col-sm-6 pt-20">
					<h6>Items</h6>
						Product Description:  MacBook Pro 2020
						Quantity: x1<br />
						Pick Up Date: 18-04-2021<br />
						Tracking No.: 11224444<br />
						Invoice No.: 8473685<br />
						Invoice Date: 02-04-2021<br />
						Unit Price (RM): 6399.00<br />
						Total Surcharge (RM): 100.00
						
						<p class="pt-20"><strong>Total Payable (RM): 200.00</strong></p>
					
					<strong>Remarks</strong>
					<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. </p> 
                    </div>
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Claim Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Policy Number">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Date of Loss">
                        </div>
                    </div>	
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Estimated Loss Amount (RM)">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Upload Documents</h6>	
					</div>

                   					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Copy of Airway Bill / Delivery Note / Consignment Note(whichever available)</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Photographs showing the damaged packaging and item.</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Purchase invoice for damaged/missing parcel.</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Repair bill/invoice for damaged item.</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Tracking report from the courier service provider</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">confirmation from courier service provider on the missing parcel</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">Require police report (applicable to theft loss)</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
					<p style="font-weight:700;">E-Payment Form duly completed and signed by Customer</p>
					<div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >   
								<a routerLink="/">Download e-payment form</a>
                        </div>
                    </div>		

                    <div class="col-lg-6 col-md-6 pt-70">
                        <button type="submit" class="default-btn btn-two">Back</button>
                    </div>
					<div class="col-lg-6 col-md-6 pt-70">
                        <button type="submit" class="default-btn btn-two">Submit Claim</button>
                    </div>
                </div>
            </form>
				
				
			</div>
	
			</div>
	</div>
   <!-- End Submit Claim Area -->
   
   
   
   <!-- Start View Draft Area -->
	<div class="container pt-70">
		<div class="row">	
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>View Draft</h2>			
				</div>
				
				<form id="contactForm" class="get-quote-form">
                <div class="row">
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Claim Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
						<label>Consignment Note./Reference No.</label>
                        <div class="form-group">							
                            <input type="text" name="name" id="name" class="form-control" placeholder="Enter Consignment Note">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
						<label>Delivered by</label>
                        <div class="form-group">
                                    <select>
                                        <option value="1">Select Courier Service</option>
                                        <option value="2">Pedins</option>
                                        <option value="3">Tenisu</option>
                                        <option value="4">Corinu</option>
                                        <option value="5">Insuxo</option>
                                    </select>	
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
						<label>Promo code</label>
                        <div class="form-group">	
                            <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Promo Code">
                        </div>
                    </div>
					
									
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Select Location</h6>	
					</div>
					
					<div class="col-lg-6 col-sm-6">
						<label>From Country</label>
                        <div class="form-group">							
                           <select>
								<option value="1">Select Country From</option>
								<option value="2">Malaysia</option>
								<option value="3">China</option>
								<option value="4">India</option>
								<option value="5">Australia</option>
							</select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>From City</label>
                        <div class="form-group">
							<select>
								<option value="1">Select City From</option>
								<option value="2">City 1</option>
								<option value="3">City 2</option>
								<option value="4">City 3</option>
								<option value="5">City 4</option>
							</select>	
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>To Country</label>
                        <div class="form-group">							
                           <select>
								<option value="1">Select Country To</option>
								<option value="2">Malaysia</option>
								<option value="3">China</option>
								<option value="4">India</option>
								<option value="5">Australia</option>
							</select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>To City</label>
                        <div class="form-group">
							<select>
								<option value="1">Select City To</option>
								<option value="2">City 1</option>
								<option value="3">City 2</option>
								<option value="4">City 3</option>
								<option value="5">City 4</option>
							</select>	
                        </div>
                    </div>
					
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Add Product</h6>	
					</div>					
					
					<div class="col-lg-6 col-sm-6">
						<label>Product Description</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Product Description">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Quantity</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Quantity">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Pickup Date</label>
                        <div class="form-group">							
                           <input type="date" name="promocode" id="promocode" class="form-control">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Tracking No.</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Tracking No.">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Invoice No.</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Invoice No.">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Invoice Date</label>
                        <div class="form-group">							
                           <input type="date" name="promocode" id="promocode" class="form-control">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Coverage</label>
                        <div class="form-group">							
                           <select>
								<option value="1">Select Coverage</option>
								<option value="2">Test 1</option>
								<option value="3">Test 2</option>
								<option value="4">Test 3</option>
								<option value="5">Test 4</option>
							</select>
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Rate</label>
                        <div class="form-group">
							<input type="text" name="promocode" id="promocode" class="form-control" placeholder="1.6%" disabled>	
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
						<label>Unit Price (RM)</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Unit Price">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
						<label>Total Value (RM)</label>
                        <div class="form-group">							
                           <input type="text" name="promocode" id="promocode" class="form-control" placeholder="Enter Total Value">
                        </div>
                    </div>					
					
					<div class="col-lg-12 col-md-12 pt-40">
                        <button type="submit" class="default-btn btn-two">Add Product</button>
                    </div>					

					<div class="col-lg-12 col-md-12 pt-20">	
						
					<table class="table">
					  <thead>
						<tr>
						  <th scope="col">No.</th>
						  <th scope="col">Prod. Desc.</th>
						  <th scope="col">Quantity</th>
						  <th scope="col">Pickup Date</th>
						  <th scope="col">Tracking No.</th>
						  <th scope="col">Invoice No.</th>
						  <th scope="col">Invoice Date</th>
						  <th scope="col">Unit Price</th>
						  <th scope="col">Total Value</th>
						  <th scope="col">Action</th>
						</tr>
					  </thead>
					  <tbody>
						<tr>
						  <td>1</td>
						  <td>MacBook Pro 2020</td>
						  <td>01</td>
						  <td>13-04-2020</td>
						  <td>1259663</td>
						  <td>VFG5896</td>						  
						  <td>13-04-2020</td>
						  <td>6392.00</td>
						  <td>200.00</td>
						  <td><i class="bx bx-pen"></i> | <i class="bx bx-trash"></i></td>
						</tr>
						<tr>
						  <td colspan="8"></td>
						  <td>Total Declared Value (MYR)</td>
						  <td>6399.00</td>
						</tr>
						<tr>
						  <td colspan="8"></td>
						  <td>Minimal Surcharge (MYR)</td>
						  <td>100.00</td>
						</tr>
						<tr>
						  <td colspan="8"></td>
						  <td>Total Payable (MYR)</td>
						  <td>200.00</td>
						</tr>
					  </tbody>
					</table>
						
					</div>
					
				<div class="col-lg-12 col-md-12 pt-20">	
				<h6>Remarks</h6>
				</div>
				
				<div class="col-lg-12 col-md-12">					
				<input type="text" name="remark" id="remark" class="form-control" placeholder="Enter remarks">							
				</div>
				<div class="col-lg-12 col-md-12">
				<span class="log-rem">
					<input id="remember-2" type="checkbox" style="margin-right:5px;">
					<label>I have agreed to the <a routerLink="/terms-conditions">terms and conditions.</a></label>
				</span>
				</div>
				<div class="col-lg-4 col-md-4 pt-20">
				<button type="submit" class="reject-btn btn-two">Delete</button>
				</div>	
				<div class="col-lg-8 col-md-8 pt-20">
				<button type="submit" class="default-btn btn-two">Save</button>
				</div>
					
                </div>
            </form>
			
			</div>
	
			</div>
	</div>
   <!-- End Submit Claim Area -->
   
	
	
	<!-- Start Request a Quote Area -->
	<div class="container pt-70">
		<div class="row">	
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>Request a Quote</h2>			
				</div>
				
				 <form id="contactForm">
                <div class="row">
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Company Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Company Name">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="email" id="email" class="form-control" placeholder="Business Registration Number">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="email" id="email" class="form-control" placeholder="Business Tax Number">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Address</h6>	
					</div>
					
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Address Line 1">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Address Line 2">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="City">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="State">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Contact Details</h6>	
					</div>
					
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Mobile Number">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Fax Number">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Email Address">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Website Address">
                        </div>
                    </div>
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Registration Details</h6>	
					</div>
					
                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Currency">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Company Turnover">
                        </div>
                    </div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" id="phone_number" class="form-control" placeholder="Territory Limit Form">
                        </div>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" placeholder="Territory Limit To">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Upload the declaration CSV</h6>	
					<p>CSV only. Maximum upload file size 3mb</p>
					</div>
					
					<div class="col-lg-6 col-sm-6">
                        <div class="form-group">
							<input type="file" id="myfile" name="myfile" class="form-control" >                            
                        </div>
                    </div>
                    

                    <div class="col-lg-12 col-md-12 pt-70">
                        <button type="submit" class="default-btn btn-two">Get A Quote</button>
                    </div>
                </div>
            </form>
				
				
			</div>
	
			</div>
	</div>
   <!-- End Request a Quote Area -->
   
   
   
   <!-- Start View Quotation Area -->
   <div class="container pt-70">
		<div class="row">	
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>View Quotation</h2>			
				</div>				
							
				<div class="row borderbox">					
				
				<div class="col-lg-6 col-md-6 pt-20">	
				Quotation ID : QW123456<br />
				Created Date : 13-04-2021<br />
				</div>
				
				<div class="col-lg-2 col-md-2 pt-20">	
				Status : Pending
				</div>
				
				<div class="col-lg-2 col-md-12 pt-20">	
				<button type="submit" class="default-btn btn-two">Accept</button>
				</div>
				
				<div class="col-lg-2 col-md-12 pt-20">
				<button type="submit" class="reject-btn btn-two">Reject</button>
				</div>
								
				
				<div class="col-lg-12 col-md-12 pt-40">	
				<h5>Company Details</h5>
				</div>
				<div class="col-lg-4 col-md-4" style="color:#000;">	
				Company Name<br />
				Business Registration No.<br />
				Courier Account No.<br />
				Company Tax No.<br />
				Country<br />
				State<br />
				City<br />
				Address<br />
				Postcode<br />
				Phone No.<br />
				Fax No.<br />
				Person To Contact<br />
				Corporate E-Mail Address<br />
				Corporate Website<br />
				Type Of Consignment<br />
				Nature of Business<br />
				Description of Goods (In Details)<br />
				Courier Company Used
				</div>
				<div class="col-lg-8 col-md-8">	
				ABC Corporate<br />
				1234567-X<br />
				112233<br />
				837986994357<br />
				Malaysia<br />
				Kuala Lumpur<br />
				Kuala Lumpur<br />
				QWER, Jalan QWER10<br />
				58100<br />
				6010-1234567<br />
				603-1234567<br />
				Sharon Tan<br />
				abc.company@gmail.com<br />
				www.abccompany.com<br />
				Parcel<br />
				F&amp;B<br />
				Food<br />
				GD Express
				</div>				
				<div class="col-lg-12 col-md-12 pt-20">	
				<h5>Product Details</h5>
				</div>
				<div class="col-lg-4 col-md-4" style="color:#000;">	
				Currency<br />
				Estimate Annual Carrying (MYR)<br />
				Max Value Per Consignment Note (MYR)<br />
				Max Daily Carrying (MYR)<br />
				Average Daily Carrying (MYR)<br />
				Company Turnover (MYR)<br />
				Shipment Fees<br />
				Territory Limit From<br />
				Territory Limit To<br />
				Mode of Conveyance
				</div>
				<div class="col-lg-8 col-md-8">	
				MYR<br />
				1000000.00<br />
				50000.00<br />
				30000.00<br />
				30000.00<br />
				100000.00<br />
				To Cover<br />
				Malaysia<br />
				Singapore<br />
				Air
				</div>	
				
				<div class="col-lg-12 col-md-12 pt-20">	
				<h5>Premium Payable</h5>
				</div>				
				<div class="col-lg-4 col-md-4">	
				Currency<br />
				Stamp Duty
				</div>
				<div class="col-lg-8 col-md-8">	
				MYR<br />
				1000000.00<br />
				</div>
				
				<div class="col-lg-12 col-md-12 pt-40">
				<span class="log-rem">
					<input id="remember-2" type="checkbox" style="margin-right:5px;">
					<label>I have agreed to the <a routerLink="/terms-conditions">terms and conditions.</a></label>
				</span>
				</div>
				<div class="col-lg-4 col-md-4 pt-20">
				<button type="submit" class="reject-btn btn-two">Cancel</button>
				</div>	
				<div class="col-lg-8 col-md-8 pt-20">
				<button type="submit" class="default-btn btn-two">Make Payment</button>
				</div>	
	
			</div>
			</div>
			
			
			
		</div>
	</div>
	<!-- End Request a Quote Area -->
	
	
	
	 <!-- Start View Order Area -->
   <div class="container pt-70">
		<div class="row">	
			<div class="col-lg-12 col-md-12">	

				<div class="section-title-full">
                <h2>View Order</h2>			
				</div>				
							
				<div class="row borderbox">					
				
				<div class="col-lg-12 col-md-12 pt-20">	
				Order ID : QW123456<br />
				Created Date : 13-04-2021<br />
				</div>	
				
				<div class="col-lg-12 col-md-12 pt-40">	
				<h5>Details</h5>
				</div>
				<div class="col-lg-4 col-md-4" style="color:#000;">	
				Consignment Note<br />
				Delivered by<br />
				Send from<br />
				Deliver to<br />
				Submitted by
				</div>
				<div class="col-lg-8 col-md-8">	
				12345<br />
				GD Express<br />
				Kepong<br />
				Bangsar<br />
				Calvin
				</div>				
				<div class="col-lg-12 col-md-12 pt-20">	
				<h5>Items</h5>
				</div>
				<div class="col-lg-4 col-md-4" style="color:#000;">	
				Product Description<br />
				Quantity<br />
				Pick Up Date<br />
				Tracking No.<br />
				Invoice No.<br />
				Invoice Date<br />
				Unit Price (RM)<br />
				Total Surcharge (RM)<br />
				Total Payable (RM)
				</div>
				<div class="col-lg-8 col-md-8">	
				MacBook Pro 2020<br />
				x1<br />
				18-04-2021<br />
				11224444<br />
				8473685<br />
				02-04-2021<br />
				6399.00<br />
				100.00<br />
				200.00
				</div>	
				
				<div class="col-lg-12 col-md-12 pt-20">	
				<h5>Remarks</h5>
				</div>
				<div class="col-lg-12 col-md-12">					
				<input type="text" name="remark" id="remark" class="form-control" placeholder="Enter remarks">							
				</div>
				
				<div class="col-lg-12 col-md-12 pt-20">
				<button type="submit" class="reject-btn btn-two">Cancel</button>
				</div>					
	
			</div>
			</div>
			
			
			
		</div>
	</div>
	<!-- End View Order Area -->
   
   
</section>