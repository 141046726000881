<!-- Start Page Title Area -->
<div class="page-title-area bg-6">
    <div class="container">
        <div class="page-title-content">
            <h2>Pricing Tables</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Pricing</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Pricing Table Area -->
<section class="pricing-table-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-box">
                    <div class="pricing-title">
                        <span>Basic</span>
                        <h1>99<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Home Insurance</li>
                        <li><i class="bx bx-x"></i>Business Insurance</li>
                        <li><i class="bx bx-check"></i>Health Insurance</li>
                        <li><i class="bx bx-x"></i>Travel Insurance</li>
                        <li><i class="bx bx-x"></i>Car Insurance</li>
                        <li><i class="bx bx-check"></i>Life Insurance</li>
                    </ul>
                    <a routerLink="/contact" class="default-btn">Book Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-pricing-box active">
                    <div class="pricing-title">
                        <span>Professional</span>
                        <h1>199<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Home Insurance</li>
                        <li><i class="bx bx-check"></i>Business Insurance</li>
                        <li><i class="bx bx-check"></i>Health Insurance</li>
                        <li><i class="bx bx-check"></i>Travel Insurance</li>
                        <li><i class="bx bx-check"></i>Car Insurance</li>
                        <li><i class="bx bx-check"></i>Life Insurance</li>
                    </ul>
                    <a routerLink="/contact" class="default-btn">Book Now</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-pricing-box">
                    <div class="pricing-title">
                        <span>Business</span>
                        <h1>299<sub>$</sub></h1>
                        <p>Per Month</p>
                    </div>
                    <ul>
                        <li><i class="bx bx-check"></i>Home Insurance</li>
                        <li><i class="bx bx-check"></i>Business Insurance</li>
                        <li><i class="bx bx-check"></i>Health Insurance</li>
                        <li><i class="bx bx-check"></i>Travel Insurance</li>
                        <li><i class="bx bx-check"></i>Car Insurance</li>
                        <li><i class="bx bx-check"></i>Life Insurance</li>
                    </ul>
                    <a routerLink="/contact" class="default-btn">Book Now</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing Table Area -->