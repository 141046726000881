<!-- Start Company Area -->
<section class="company-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="company-img">
                    <!--<div class="trusted">
                        <span>100%</span>
                        <h3>Trusted Company</h3>
                        <p>You can depend on us for our company's services</p>
                    </div>-->
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-content ptb-100">
                    <span class="top-title">Welcome to <strong>Smart Parcel Solutions</strong></span>
                    <h2>Standard Of Packaging</h2>
                    <p><span class="some-bold">Step 1</span><br />Wrapping each item individually with a bubble wrap (at least wrap the item with 3 layers of bubble wrap) and carefully placing it in a double thickness box. It is advisable to use "an industrial bubble wrap".</p>
					
					<p><span class="some-bold">Step 2</span><br />Next, after use the "bubble wrap", we suggest you to use "PE Stretch Film" in order to maximize the protection as to avoid the impact from other heavy shipment.</p>
					
					<p><span class="some-bold">Step 3</span><br />Kindly put the box inside the other box (Box-in-Box Packaging Method) as to protect the inside box and item from damage.</p>
					
					<p><span class="some-bold">Step 4</span><br />Basic Box-In-Box Packaging Method</p>
					
					<ul>
						<li>Wrap product/item individually with at least 3 layers of bubble wrap/ 2" (5.08 cm) thickness of bubble wrap or foam material to fit snugly inside a corrugated box. (See Figure 1)</li>
						<li>Use a second, outer container that is at least 6" (15.24 cm) longer, wider and deeper than the inner box.</li>
						<li>Fill the bottom, sides and upper of the outer box with 2" to 3" (5.08cm cm to 7.62 cm) of cushioning material.</li>
						<li>Place the inner box in the center of the outer box on top of the cushioning material.</li>
						<li>Fill voids of all sides with more cushioning material (e.g loose-fill materials/foam materials/ Styrofoam balls.</li>
						<li>Seal box.</li>
					</ul>
						
						
                    <a routerLink="/about-us" class="default-btn">Read More</a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Company Area -->