<!-- Start Banner Area -->
<section class="banner-area jarallax" data-jarallax='{"speed": 0.3}'>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-12">
                        <div class="banner-content">
                            <span class="wow fadeInDown" data-wow-delay="1s">SHIP WITH A PEACE OF MIND</span>
                            <h1 class="wow fadeInDown" data-wow-delay="1s">Get professional shipping insurance coverage</h1>
                            <p class="wow fadeInLeft" data-wow-delay="1s">Accidents happens. That's why we are here to keep you covered when it happens. Opt in to our parcel insurance to keep your parcel covered. Get your estimate parcel insurance through the awesome mechanism at the side.</p>
                            <div class="banner-btn wow fadeInUp" data-wow-delay="1s">
								<span class="wow fadeInLeft" data-wow-delay="1s" style="font-weight:bold; color:#000;">Can't get your quotation? Try our special quotation service.</span>
                                <a routerLink="/requestaquote" class="default-btn"> Get a Quote</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-5 col-md-12">
					
						<div class="banner-img"> 
						<div class="banner-shape-1">
                                <img src="assets/img/banner/banner-shape-1.png" alt="Image">
                            </div>
						</div>							
							
						<div class="banner-form">
						<form id="contactForm" class="formpadding get-quote-form">	

						
						<h4 style="padding:20px; text-align:center; font-size: 32px;">Instant Quote</h4>
						
						<div class="col-lg-12 col-sm-12">
							<label>Declare Shippment</label>
							<div class="form-group">							
								<input type="text" name="name" id="name" class="form-control" placeholder="Declare Shipment (RM)">
							</div>
						</div>

						<div class="col-lg-12 col-sm-12">
						<label>Customer Type</label>
							<div class="form-group">
										<select>
											<option value="1">Select Customer Type</option>
											<option value="2">Cust.1</option>
											<option value="3">Cust.2</option>
											<option value="4">Cust.3</option>
											<option value="5">Cust.4</option>
										</select>	
							</div>
						</div>
						
						<div class="col-lg-12 col-sm-12">
							<label>Partner Name</label>
							<div class="form-group">
										<select>
											<option value="1">Select Partner Name</option>
											<option value="2">Partner.1</option>
											<option value="3">Partner.2</option>
											<option value="4">Partner.3</option>
											<option value="5">Partner.4</option>
										</select>	
							</div>
						</div>
						
						<div class="col-lg-12 col-sm-12">
							<label>Coverage Surcharge (RM)</label>
							<div class="form-group">	
								<input type="text" name="promocode" id="promocode" class="form-control" placeholder="Coverage Surcharge (RM)">
							</div>
						</div>
						<div class="col-lg-12 col-md-12">
							<button type="submit" class="default-btn btn-two">Submit</button>
						</div>
					</form>						
						</div>
                        <div class="banner-img">                            
                            <ul>
                                <li><a href="#" target="_blank">Facebook</a></li>
                                <li><a href="#" target="_blank">Instagram</a></li>
                                <li><a href="#" target="_blank">Twitter</a></li>
                                <li><a href="#" target="_blank">Linkedin</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Banner Area -->