<!-- Start Footer Area -->
<footer class="footer-area bg-color pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget single-bg">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/logo.png" alt="Image">
                    </a>
                    <p>Smart Parcel is a parcel insurance service provider. We are dedicated to provide coverage on a wide range of delivery goods, giving people the peace of mind on their deliveries. Follow us on social websites to stay up to date on our news and activities.</p>
                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin-square"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Contact</h3>

                    <ul class="address">
                        <li>
                            <span>Phone:</span>
                            <a href="tel:+1-(514)-312-6677">+1 (514) 312-6677</a>
                        </li>
                        <li>
                            <span>Email:</span>
                             <a href="mailto:hello@smartparcelsolutions.com">hello@smartparcelsolutions.com</a>
                            
                        </li>
                        <li class="location">
                            <span>Address:</span>
                            21B, Sample Address Location, Plot 2A, Klang, Malaysia
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Navigate</h3>

                    <ul class="import-link">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/beapartner">Join SmartParcel</a></li>
                        <li><a routerLink="/requestaquote">Request a Quote</a></li>
                        <li><a routerLink="/claims">Claims</a></li>
                        <li><a routerLink="/howto">How-to</a></li>
                        <li><a routerLink="/faq">FAQ</a></li>
						<li><a routerLink="/contact-us">Contact Us</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">               
				<div class="single-footer-widget">
                    <h3>Services</h3>
					
					<ul class="import-link">
                        <li><a routerLink="/aboutus">About Us</a></li>
                        <li><a routerLink="/excludeitems">Exclude Items &amp; Countries</a></li>
                        <li><a routerLink="/refund">Refund</a></li>
                        <li><a routerLink="/coverage">Coverage</a></li>
                        <li><a routerLink="/privacy-policy">Privacy</a></li>
                        <li><a routerLink="/terms-conditions">Terms</a></li>
                    </ul>                   
                </div>
            </div>
        </div>
    </div>
</footer>
<!-- End Footer Area -->

<!-- Start Copy Right Area -->
<div class="copy-right-area bg-color">
    <div class="container">
        <p>Copyright @2021 <strong>SPS</strong> | All Rights Reserved.</p>
    </div>
</div>
<!-- End Copy Right Area -->

<!-- Start Log In Modal -->
<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <i class="bx bx-x"></i>
            </button>

            <div class="modal-body">
                <div class="container">
                    <div class="contact-form-action">
                        <div class="account-title">
                            <h2>Log In to Your Account</h2>
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Email or Phone</label>
                                        <input class="form-control" type="text" name="name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input class="form-control" type="password" name="password">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="login-action">
                                        <span class="log-rem">
                                            <input id="remember" type="checkbox">
                                            <label for="remember">Remember me!</label>
                                        </span>
                                        <span class="forgot-login">
                                            <a routerLink="/recover-password">Forgot your password?</a>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="default-btn" type="submit">Log In</button>
                                </div>
                                <div class="col-12">
                                    <p>Have an account? <a routerLink="/register">Registration Now!</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Log In Modal -->

<!-- Start Register Modal -->
<div class="modal fade" id="staticBackdrop-2" data-backdrop="static" data-keyboard="false" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <i class="bx bx-x"></i>
            </button>

            <div class="modal-body">
                <div class="container">
                    <div class="contact-form-action">
                        <div class="account-title">
                            <h2>Register Your Account</h2>
                        </div>
                        <form>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Full name</label>
                                        <input class="form-control" type="text" name="name">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Email address</label>
                                        <input class="form-control" type="email" name="email">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Mobile no.</label>
                                        <input class="form-control" type="text" name="Number">
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group">
                                        <label>Password</label>
                                        <input class="form-control" type="text" name="password">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="show">
                                        <input id="remember-1" type="checkbox">
                                        <label>Show password ?</label>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <button class="default-btn register" type="submit">Register Now</button>
                                </div>
                                <div class="col-12">
                                    <p>Have an account? <a routerLink="/log-in">Login now!</a></p>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Register Modal -->