<!-- Start Page Title Area -->
<div class="page-title-area bg-requestaquote">
    <div class="container">
        <div class="page-title-content">
            <h2>How To</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">How To</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->

<!-- Start Future Area -->
<section class="future-area future-area-two future-area-three pt-100 pb-70">
    <div class="container">
        <div class="row">
		
			<div class="section-title-full">
                <span>Learn About Our Process</span>
                <h2>How To</h2>	
				<p>To make sure any fragile items you want to post get to their destination safely, we recommend shipper to follow some parcel packing guidelines. These tips will helps minimise of risks of damages during the loading, unloading and transit of goods under bad weathers, poor road conditions and other hazardous and accidents.</p>
            </div>
			
            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="bx bx-box"></i>
                    <h3>Declare items including values</h3>                   
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="bx bx-file-find"></i>
                    <h3>Assessment by partners</h3>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-future-box">
                    <i class="bx bx-briefcase"></i>
                    <h3>Payment &amp; Get Insured</h3>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Future Area -->

<section class="howto-area howto-area-three pt-40 pb-70"> 

			<div class="container">
				<div class="row">
					<div class="col-lg-6 col-md-12">
					<h3>Do's</h3>
                    <p>Things you should follow</p>
					
					<div class="container">
					<div class="row">
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>Use strong and undamaged cardboard boxes.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-1.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Use dividers when sending more than one items.</p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>Close proper and secure the box with packaging tape.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-1.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Select a cardboard box of appropriate size and shape.</p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>Peel off the old labels from the used cardboard box.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-1.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Wrap in bubble foil individual items in the shipment.</p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-1.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>Use packaging chips for sealing.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-1.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Use envelopes with bubbles.</p>
							</div>
						</div>
					</div>
							
					</div>
					</div>
					
					
					</div>
					
					<div class="col-lg-6 col-md-12">
					<h3>Don't</h3>
                    <p>Things you should avaoid</p>
					
					
					<div class="container">
					<div class="row">
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>Do not use old and damaged boxes.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-2.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Do not send items in gift packaging.</p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>The "fragile" label does not substitute the reliable package.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-2.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Remember to seal the goods in the cardboard box.</p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>Remember to remove old labels.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-2.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Do not overfill the cardboard box.</p>
							</div>
						</div>
					</div>
					
					<div class="col-lg-6 col-md-6">					
					<div class="featured-services-box">
                    <a routerLink="/business-insurance">
                        <img src="assets/img/services/services-2.jpg" alt="Image">
                    </a>
                    <div class="featured-services-title">
								<p>Do not send prohibited and dangerous goods.</p>
							</div>
						</div>
					</div>

					<div class="col-lg-6 col-md-6">
						<div class="featured-services-box">
							<a routerLink="/business-insurance">
								<img src="assets/img/services/services-2.jpg" alt="Image">
							</a>
							<div class="featured-services-title">
								<p>Do not use bags for outer packaging.</p>
							</div>
						</div>
					</div>
							
					</div>
					</div>
					
					</div>
				</div>
			</div>
 
</section>

<app-homeone-company></app-homeone-company>
<app-partner></app-partner>