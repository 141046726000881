<!-- Start Page Title Area -->
<div class="page-title-area bg-profile">
    <div class="container">
        <div class="page-title-content">
            <h2>Profile</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Profile</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="profile-area profile-area-three pt-100 pb-70">
   
   <div class="container">
	<div class="row">
					<div class="col-lg-3 col-md-3">					
					<div class="left-widget">
                    <h3>My Account</h3>
                    <ul class="import-link-left">
                        <li><a routerLink="/profile">My Profile</a></li>
                        <li><a routerLink="/declaration">Declaration</a></li>
                        <li><a routerLink="/profileclaims">Claims</a></li>
						<li><a routerLink="/mycomponents">My Components</a></li>
                        <li><a routerLink="/">Logout</a></li>
                    </ul>
					</div>				
					
					</div>
		
		<div class="col-lg-9 col-md-9">
		
		<div class="col-lg-12 col-sm-12 mb-30">
					<h2>User Profile</h2>	
			</div>
			
			<form id="contactForm">
                <div class="row">
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Company Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Corporate ID">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="email" name="cname" id="cname" class="form-control" placeholder="Corporate Name">
                        </div>
                    </div>
										
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="btype" id="btype" class="form-control" placeholder="Business Type">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="cnumber" id="cnumber" class="form-control" placeholder="Contact Number">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Email Address">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="date" name="jdate" id="jdate" class="form-control">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Login Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="username" id="username" class="form-control" placeholder="Username">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="password" name="password" id="password" class="form-control" placeholder="Password">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="password" name="cpassword" id="cpassword" class="form-control" placeholder="Confirm Password">
                        </div>
                    </div>
					
                    <div class="col-lg-12 col-md-12 pt-70">
                        <button type="submit" class="default-btn btn-two">Submit</button>
                    </div>
                </div>
            </form>
			
			
			<div class="col-lg-12 col-sm-12 mt-100 mb-30">
					<h2>Partner Branch Profile</h2>	
			</div>
			
			
			<form id="contactForm" style="margin-top:20px;">
                <div class="row">
				
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Branch ID</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Branch ID" disabled>
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="email" name="cname" id="cname" class="form-control" placeholder="Corporate Name">
                        </div>
                    </div>
										
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="btype" id="btype" class="form-control" placeholder="Location Name">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="cnumber" id="cnumber" class="form-control" placeholder="Address 1">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="email" id="email" class="form-control" placeholder="Address 2">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="jdate" id="jdate" class="form-control" placeholder="City">
                        </div>
                    </div>
					
					 <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="btype" id="btype" class="form-control" placeholder="State">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="cnumber" id="cnumber" class="form-control" placeholder="Postcode">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="email" id="email" class="form-control" placeholder="Contact Number">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="jdate" id="jdate" class="form-control" placeholder="Email Address">
                        </div>
                    </div>
					
					 <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="jdate" id="jdate" class="form-control" placeholder="01-01-2020" disabled>
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Person In Charge Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="name" id="name" class="form-control" placeholder="Name">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="cno" id="cno" class="form-control" placeholder="Contact Number">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="email" name="email" id="email" class="form-control" placeholder="Email Address">
                        </div>
                    </div>
					
					<div class="col-lg-12 col-sm-12 mt-30 mb-30">
					<h6>Login Details</h6>	
					</div>
					
                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="text" name="username" id="username" class="form-control" placeholder="Username">
                        </div>
                    </div>

                    <div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="password" name="password" id="password" class="form-control" placeholder="Password">
                        </div>
                    </div>
					
					<div class="col-lg-4 col-sm-4">
                        <div class="form-group">
                            <input type="password" name="cpassword" id="cpassword" class="form-control" placeholder="Confirm Password">
                        </div>
                    </div>
					
                    <div class="col-lg-12 col-md-12 pt-70">
                        <button type="submit" class="default-btn btn-two">Submit</button>
                    </div>
                </div>
            </form>
	</div>							
	</div>
	</div>
   
   
</section>