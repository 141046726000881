<!-- Start Page Title Area -->
<div class="page-title-area bg-profile">
    <div class="container">
        <div class="page-title-content">
            <h2>Declaration</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">Declaration</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="profile-area profile-area-three pt-100 pb-70">
   
   <div class="container">
	<div class="row">
					<div class="col-lg-3 col-md-3">					
					<div class="left-widget">
                    <h3>My Account</h3>
                    <ul class="import-link-left">
                        <li><a routerLink="/profile">My Profile</a></li>
                        <li><a routerLink="/declaration">Declaration</a></li>
                        <li><a routerLink="/profileclaims">Claims</a></li>
						<li><a routerLink="/mycomponents">My Components</a></li>
                        <li><a routerLink="/">Logout</a></li>
                    </ul>
					</div>				
					
					</div>

					<div class="col-lg-9 col-md-9">
					
					<div class="container" class="pb-40">
					<div class="row">
					<div class="col-lg-9 col-md-9">
						<form class="search-box">
							<input type="text" name="Search" placeholder="Search Consignment Note" class="form-control">
						</form>
					</div>
					<div class="col-lg-3 col-md-3">
						<div class="get-quotearea">
							<a routerLink="/getaquote" class="default-btn">Get a Quote</a>
						</div>
					</div>
					</div>
					</div>		
					
					<table class="table table-striped">
					  <thead>
						<tr>
						  <th scope="col">Quotation ID</th>
						  <th scope="col">Product Description</th>
						  <th scope="col">Created Date</th>
						  <th scope="col">Amount (RM)</th>
						  <th scope="col">Status</th>
						  <th scope="col">Action</th>
						</tr>
					  </thead>
					  <tbody>
						<tr>
						  <td>QW123456</td>
						  <td>MacBook Pro 2020</td>
						  <td>13-04-2020</td>
						  <td>128.00</td>
						  <td>Pending</td>
						  <td><a routerLink="/" style="font-weight:700;">View</a> | <a routerLink="/" style="font-weight:700;">Claim</a></td>
						</tr>
						<tr>
						 <td>QW123456</td>
						  <td>MacBook Pro 2021</td>
						  <td>13-04-2020</td>
						  <td>128.00</td>
						  <td>Pending</td>
						  <td><a routerLink="/" style="font-weight:700;">View</a> | <a routerLink="/" style="font-weight:700;">Claim</a></td>
						</tr>
						<tr>
						  <td>QW123456</td>
						  <td>MacBook Pro 2020</td>
						  <td>13-04-2020</td>
						  <td>128.00</td>
						  <td>Approved</td>
						  <td><a routerLink="/" style="font-weight:700;">View</a> | <a routerLink="/" style="font-weight:700;">Claim</a></td>
						</tr>
					  </tbody>
					</table>
					</div>							
	</div>
	</div>
   
   
</section>