import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { HomeDemoOneComponent } from './components/pages/home-demo-one/home-demo-one.component';
import { HomeDemoTwoComponent } from './components/pages/home-demo-two/home-demo-two.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { BlogGridPageComponent } from './components/pages/blog-grid-page/blog-grid-page.component';
import { BlogRightSidebarPageComponent } from './components/pages/blog-right-sidebar-page/blog-right-sidebar-page.component';
import { BlogDetailsPageComponent } from './components/pages/blog-details-page/blog-details-page.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { BusinessInsurancePageComponent } from './components/pages/business-insurance-page/business-insurance-page.component';
import { HealthInsurancePageComponent } from './components/pages/health-insurance-page/health-insurance-page.component';
import { LifeInsurancePageComponent } from './components/pages/life-insurance-page/life-insurance-page.component';
import { CarInsurancePageComponent } from './components/pages/car-insurance-page/car-insurance-page.component';
import { ComingSoonPageComponent } from './components/pages/coming-soon-page/coming-soon-page.component';
import { TermsConditionsPageComponent } from './components/pages/terms-conditions-page/terms-conditions-page.component';
import { PrivacyPolicyPageComponent } from './components/pages/privacy-policy-page/privacy-policy-page.component';
import { FaqPageComponent } from './components/pages/faq-page/faq-page.component';
import { BeapartnerPageComponent } from './components/pages/beapartner-page/beapartner-page.component';
import { RequestaquotePageComponent } from './components/pages/requestaquote-page/requestaquote-page.component';
import { HowtoPageComponent } from './components/pages/howto-page/howto-page.component';
import { AboutPageComponent } from './components/pages/aboutus-page/aboutus-page.component';
import { OtpPageComponent } from './components/pages/otp-page/otp-page.component';
import { SuccessPageComponent } from './components/pages/success-page/success-page.component';
import { ExcludeitemsComponent } from './components/pages/excludeitems-page/excludeitems-page.component';
import { ClaimsPageComponent } from './components/pages/claims-page/claims-page.component';
import { ClaimsFormPageComponent } from './components/pages/claimsform-page/claimsform-page.component';
import { PricingPageComponent } from './components/pages/pricing-page/pricing-page.component';
import { TeamPageComponent } from './components/pages/team-page/team-page.component';
import { ServicesPageComponent } from './components/pages/services-page/services-page.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { TeamComponent } from './components/common/team/team.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { MyAccountPageComponent } from './components/pages/my-account-page/my-account-page.component';
import { LoginPageComponent } from './components/pages/login-page/login-page.component';
import { RegisterPageComponent } from './components/pages/register-page/register-page.component';
import { RefundComponent } from './components/pages/refund-page/refund-page.component';
import { CoverageComponent } from './components/pages/coverage-page/coverage-page.component';
import { RecoverPasswordPageComponent } from './components/pages/recover-password-page/recover-password-page.component';
import { ProfilePageComponent } from './components/pages/profile-page/profile-page.component';
import { DeclarationPageComponent } from './components/pages/declaration-page/declaration-page.component';
import { ProfileclaimsPageComponent } from './components/pages/profileclaims-page/profileclaims-page.component';
import { MycomponentsPageComponent } from './components/pages/mycomponents-page/mycomponents-page.component';
import { HomeoneBannerComponent } from './components/pages/home-demo-one/homeone-banner/homeone-banner.component';
import { HomeoneFaqComponent } from './components/pages/home-demo-one/homeone-faq/homeone-faq.component';
import { HomeoneCompanyComponent } from './components/pages/home-demo-one/homeone-company/homeone-company.component';
import { HomeoneFeedbackComponent } from './components/pages/home-demo-one/homeone-feedback/homeone-feedback.component';
import { HomeoneFutureComponent } from './components/pages/home-demo-one/homeone-future/homeone-future.component';
import { HomeoneGetAQuoteComponent } from './components/pages/home-demo-one/homeone-get-a-quote/homeone-get-a-quote.component';
import { HomeoneServicesComponent } from './components/pages/home-demo-one/homeone-services/homeone-services.component';
import { HomeoneWhyChooseUsComponent } from './components/pages/home-demo-one/homeone-why-choose-us/homeone-why-choose-us.component';
import { HometwoFeedbackComponent } from './components/pages/home-demo-two/hometwo-feedback/hometwo-feedback.component';
import { HometwoWhyChooseUsComponent } from './components/pages/home-demo-two/hometwo-why-choose-us/hometwo-why-choose-us.component';
import { HometwoGetAQuoteComponent } from './components/pages/home-demo-two/hometwo-get-a-quote/hometwo-get-a-quote.component';
import { HometwoServicesComponent } from './components/pages/home-demo-two/hometwo-services/hometwo-services.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { HometwoWelcomeComponent } from './components/pages/home-demo-two/hometwo-welcome/hometwo-welcome.component';
import { HometwoFutureComponent } from './components/pages/home-demo-two/hometwo-future/hometwo-future.component';
import { HometwoBannerComponent } from './components/pages/home-demo-two/hometwo-banner/hometwo-banner.component';
import { HomeDemoThreeComponent } from './components/pages/home-demo-three/home-demo-three.component';
import { HomethreeBannerComponent } from './components/pages/home-demo-three/homethree-banner/homethree-banner.component';
import { HomethreeFutureComponent } from './components/pages/home-demo-three/homethree-future/homethree-future.component';
import { HomethreeCompanyComponent } from './components/pages/home-demo-three/homethree-company/homethree-company.component';
import { HomethreeServicesComponent } from './components/pages/home-demo-three/homethree-services/homethree-services.component';
import { HomethreeWhyChooseUsComponent } from './components/pages/home-demo-three/homethree-why-choose-us/homethree-why-choose-us.component';
import { HomethreeGetAQuoteComponent } from './components/pages/home-demo-three/homethree-get-a-quote/homethree-get-a-quote.component';
import { HomethreeFaqComponent } from './components/pages/home-demo-three/homethree-faq/homethree-faq.component';
import { HomethreeFeedbackComponent } from './components/pages/home-demo-three/homethree-feedback/homethree-feedback.component';
import { TestimonialsPageComponent } from './components/pages/testimonials-page/testimonials-page.component';
import { AboutUsPageComponent } from './components/pages/about-us-page/about-us-page.component';

@NgModule({
    declarations: [
        AppComponent,
        FooterComponent,
        NavbarComponent,
        HomeDemoOneComponent,
        HomeDemoTwoComponent,
        ContactPageComponent,
        BlogGridPageComponent,
        BlogRightSidebarPageComponent,
        BlogDetailsPageComponent,
        NotFoundComponent,
        BusinessInsurancePageComponent,
        HealthInsurancePageComponent,
        LifeInsurancePageComponent,
        CarInsurancePageComponent,
        ComingSoonPageComponent,
        TermsConditionsPageComponent,
        PrivacyPolicyPageComponent,
        FaqPageComponent,
		BeapartnerPageComponent,
		RequestaquotePageComponent,
		HowtoPageComponent,
		AboutPageComponent,
        ClaimsPageComponent,
		ClaimsFormPageComponent,
        PricingPageComponent,
        TeamPageComponent,
        ServicesPageComponent,
        PartnerComponent,
        TeamComponent,
        BlogComponent,
        MyAccountPageComponent,
		LoginPageComponent,
		RegisterPageComponent,
		OtpPageComponent,
		SuccessPageComponent,
		ExcludeitemsComponent,
		RefundComponent,
		CoverageComponent,
		ProfilePageComponent,
		DeclarationPageComponent,
		ProfileclaimsPageComponent,
		MycomponentsPageComponent,
        RecoverPasswordPageComponent,
        HomeoneBannerComponent,
        HomeoneFaqComponent,
        HomeoneCompanyComponent,
        HomeoneFeedbackComponent,
        HomeoneFutureComponent,
        HomeoneGetAQuoteComponent,
        HomeoneServicesComponent,
        HomeoneWhyChooseUsComponent,
        HometwoFeedbackComponent,
        HometwoWhyChooseUsComponent,
        HometwoGetAQuoteComponent,
        HometwoServicesComponent,
        FunfactsComponent,
        HometwoWelcomeComponent,
        HometwoFutureComponent,
        HometwoBannerComponent,
        HomeDemoThreeComponent,
        HomethreeBannerComponent,
        HomethreeFutureComponent,
        HomethreeCompanyComponent,
        HomethreeServicesComponent,
        HomethreeWhyChooseUsComponent,
        HomethreeGetAQuoteComponent,
        HomethreeFaqComponent,
        HomethreeFeedbackComponent,
        TestimonialsPageComponent,
        AboutUsPageComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        CarouselModule,
        NgxScrollTopModule
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }