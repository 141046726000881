<!-- Start Page Title Area -->
<div class="page-title-area bg-aboutus">
    <div class="container">
        <div class="page-title-content">
            <h2>About Smart Parcel</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li class="active">About Smart Parcel</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Title Area -->

<!-- Start Featured Services Area -->
<section class="aboutus-area aboutus-area-three pt-100 pb-70">
    <div class="container">
        <div class="aboutus-contact-area">
            <div class="section-title-full">
                <span>Smart Parcel Solutions</span>
                <h2>All About Us</h2>	
				<p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias adipisicing elit. Quibusdam aliquam ut ad debitis maxime alias vitae magnam, accusamus amet pariatur tenetur totam deserunt dolore provident nobis impedit non vel quo? aliquam ut ad debitis maxime alias</p>
            </div>
           
        </div>
    </div>
</section>

<app-homeone-feedback></app-homeone-feedback>

<app-partner></app-partner>