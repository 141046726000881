<!-- Start Why Choose Us Area -->
<section class="why-choose-us-area bg-color">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12 p-0">
                <div class="choose-us-content ptb-100">
                    <span class="top-title">Why Choose Us</span>
                    <h2>Smart Parcel provides world class parcel insurance with an affordable price.</h2>
                    <p>Get instant quotation from Smart Parcel. Make quick booking and get clear update of all your parcels under one dashboard.</p>
                    <!--<ul>
                        <li><i class="flaticon-tick"></i> 25 Years of Experience</li>
                        <li><i class="flaticon-tick"></i> Save You Money</li>
                        <li><i class="flaticon-tick"></i> A Fast & Easy Application</li>
                        <li><i class="flaticon-tick"></i> 24/7 Support</li>
                        <li><i class="flaticon-tick"></i> Growing Your Business</li>
                    </ul>-->
					
					 <div class="row">
                        <div class="col-lg-6 col-md-6">
						<h6>About Us</h6>
                        <p>Smart Parcel is a web-based parcel insurer and E-commerce insurance solutions provider. To put it simply, we provide easy-access solution for parcel insurance service digitally.</p>
						<a routerLink="/">Learn More</a>
                        </div>

                        <div class="col-lg-6 col-md-6">
						<h6>FAQ</h6>
                        <p>Smart Parcel provides insurance solution services to delivery services, covering parcel from unexpected damage/losses. </p>
						<a routerLink="/faq">Learn More</a>
                        </div>
                    </div>	
                </div>
            </div>

            <div class="col-lg-6 col-md-12 p-0">
                <div class="why-choose-slider-content">
                    <div class="why-choose-us-slider">
                        <owl-carousel-o [options]="whyChooseUsSliderOptions">
                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-1"></div>
                            </ng-template>

                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-2"></div>
                            </ng-template>

                            <ng-template carouselSlide>
                                <div class="why-choose-us-img bg-3"></div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                    <!--<div class="success">
                        <span>100%</span>
                        <h3>Success Rate</h3>
                    </div>-->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Why Choose Us Area -->