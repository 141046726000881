<!-- Start Future Area -->
<section class="future-area future-area-two future-area-three pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-future-box">
                    <i class="bx bxs-certification"></i>
                    <h3>Trustworthy Company</h3>
                    <p>We are partner with Allianz one of the leading insurers, both property and casualty and life and health. Allianz provides world class insurance services throughout the years and has a strong reputation in the insurance industry.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-future-box active" style="padding-bottom:115px;">
                    <i class="bx bx-world"></i>
                    <h3>Worldwide Coverage</h3>
                    <p>All Risks coverage for inbounds outbounds over 134 countries.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-future-box" style="padding-bottom:60px;">
                    <i class="bx bx-book-open"></i>
                    <h3>Online Claim</h3>
                    <p>Ease of access to claim your lost/damaged parcel online. No hassle of showing up at retail stores etc, just online for the whole reimbursement process.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Future Area -->